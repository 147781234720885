.App {
  text-align: center;
  padding: 20px;
}

.recordings {
  display: flex;
  justify-content: center;
  /* align-content: center; */
  align-items: center;
  flex-direction: column;
}

.recordings > * {
  padding: 20px;
}

button {
  width: 80px;
  height: 60px;
  margin: 10px;
}